import React from "react";
import { Badge } from "reactstrap";
import { backgroundColors } from "contexts/BackgroundColorContext";
import { useState, useEffect } from "react";
import useOHLCContext from "contexts/OHLCContext";
import { getCurrentTrend } from "utils/utils";

function CurrentTrend(props) {
  const { ohlc, setOhlc } = useOHLCContext();
  const [vtrend, setVTrend] = useState({
    color: "",
    trend: "",
    amount: "",
    jumping: "",
  });

  useEffect(() => {
    if (ohlc) {
      console.log("c", ohlc);
      const trend = getCurrentTrend(ohlc);
      const color = trend.trend ? "rgba(53, 255, 235, 0.5)" : "rgba(255, 99, 132, 0.5)";
      const side_text = trend.trend ? "Long" : "Short";
      const trend_string = side_text + " * " + trend.count.toString();
      const size = parseFloat(ohlc[ohlc.length - 1][6]);
      const jumping = trend.count >= 3 ? "Jumping" : "";
      setVTrend({
        color: color,
        trend: trend_string,
        amount: Math.round(size * 100) / 100,
        jumping: jumping,
      });
    }
  }, [ohlc]);

  return (
    <>
      <li className="header-title text-center">
        <h4>Current Trends</h4>
      </li>
      <li className="adjustments-line">
        <div className="badge-colors text-center">
          <Badge
            color="primary"
            className={props.bgColor === backgroundColors.primary ? "active" : ""}
            onClick={() => {
              props.handleBgClick(backgroundColors.primary);
            }}
          />{" "}
          {/* <Badge
            color="info"
            className={
                props.bgColor === backgroundColors.blue ? "active" : ""
            }
            onClick={() => {
                props.handleBgClick(backgroundColors.blue);
            }}
            />{" "} */}
          <Badge
            color="success"
            className={props.bgColor === backgroundColors.green ? "active" : ""}
            onClick={() => {
              props.handleBgClick(backgroundColors.green);
            }}
          />{" "}
        </div>
      </li>
      <li className="adjustments-line text-center color-change">
        <h3 style={{ color: vtrend.color }}>{vtrend.trend}</h3>
        <h4 style={{ color: vtrend.color }}>{vtrend.amount}</h4>
        <h2 style={{ color: vtrend.color }}>{vtrend.jumping}</h2>
        {/* <TrendText data={vtrend}/> */}
      </li>
    </>
  );
}

export default CurrentTrend;
