import React from "react";
import { scaleTime } from "d3-scale";
import { utcMinute } from "d3-time";
import { format } from "d3-format";
import { ChartCanvas, Chart } from "react-stockcharts";
import { CandlestickSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { MouseCoordinateY } from "react-stockcharts/lib/coordinates";
import { OHLCTooltip } from "react-stockcharts/lib/tooltip";
import { last, timeIntervalBarWidth } from "react-stockcharts/lib/utils";

export default function CandleStickChart(props) {
  const data = props?.data || null;
  const xAccessor = (d) => d.date;
  const xExtents = [xAccessor(last(data)), xAccessor(data[data.length - 100])];

  return (
    <ChartCanvas
      height={400}
      ratio={props.ratio}
      width={props.width}
      margin={{ left: 50, right: 50, top: 10, bottom: 30 }}
      type={props.type}
      seriesName="MSFT"
      data={props.data}
      xAccessor={xAccessor}
      xScale={scaleTime()}
      xExtents={xExtents}
    >
      <Chart id={1} yExtents={(d) => [d.high, d.low]}>
        <XAxis axisAt="bottom" orient="bottom" ticks={6} />
        <YAxis axisAt="left" orient="left" ticks={5} />
        <MouseCoordinateY at="right" orient="right" displayFormat={format(".2f")} />
        <CandlestickSeries
          width={timeIntervalBarWidth(utcMinute)}
          fill={(d) => (d.close > d.open ? "#1d8cf8" : "#f5365c")}
        />
        <OHLCTooltip origin={[-40, -10]} />
      </Chart>
    </ChartCanvas>
  );
}

// CandleStickChart.propTypes = {
//   data: PropTypes.array.isRequired,
//   width: PropTypes.number.isRequired,
//   ratio: PropTypes.number.isRequired,
//   type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
// };

// CandleStickChart.defaultProps = {
//   type: "svg",
// };
// CandleStickChart = fitWidth(CandleStickChart);
