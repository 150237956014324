import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { Button, ButtonGroup, Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import useSymbolTfContext from "contexts/SymbolTFContext";
import useOHLCContext from "contexts/OHLCContext";
// import OHLCChart from "./chartComponents/OHLCChart";
import CandleStickChart from "./chartComponents/CandleStickChart";
import { parseData } from "utils/utils";
export default function PriceChart(props) {
  const { symbolTf, setSymbolTF } = useSymbolTfContext();
  const [curPrice, setCurPrice] = useState(0);
  const [tfs, setTfs] = useState([]);
  const { ohlc, setOhlc } = useOHLCContext();

  const getTfs = () => {
    return ["1min", "5min", "15min", "30min"];
  };

  useEffect(() => {
    const results = getTfs();
    setTfs(results);
  }, []);

  return (
    <Col xs="12">
      <Card className="card-chart">
        <CardHeader>
          <Row>
            <Col className="text-left" sm="3">
              <h5 className="card-category">Price Chart</h5>
              <CardTitle tag="h2">
                {symbolTf?.symbol} - {symbolTf?.price} : {symbolTf?.tf} Chart
              </CardTitle>
            </Col>
            <Col className="text-left" sm="3">
              <ButtonGroup className="btn-group-toggle float-right" data-toggle="buttons">
                {tfs.map((item, index) => (
                  <Button
                    tag="label"
                    className={classNames("btn-simple", {
                      active: symbolTf?.tf === item,
                    })}
                    color={"success"}
                    id={index}
                    size="sm"
                    onClick={() => setSymbolTF({ ...symbolTf, tf: item })}
                    key={index}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">{item}</span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-single-02" />
                    </span>
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {ohlc ? <CandleStickChart type="svg" data={parseData(ohlc)} ratio={1} width={1500} /> : null}
        </CardBody>
      </Card>
    </Col>
  );
}
