import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { Bar } from "react-chartjs-2";
import { chartExample3 } from "variables/charts";
import useSymbolTfContext from "contexts/SymbolTFContext";
import useBestAskBidContext from "contexts/BestAskBidContext";
import { useEffect, useState } from "react";
import { getABTrend } from "utils/utils";
export default function BidTrendsPanel(props) {
  const { symbolTf, setSymbolTf } = useSymbolTfContext();
  const { bestAB, setBestAB } = useBestAskBidContext();
  const [trend, setTrend] = useState();
  useEffect(() => {
    if (bestAB.datasets[1].data) {
      const bids = bestAB.datasets[1].data;
      setTrend(getABTrend(bids));
    }
  }, [bestAB]);
  return (
    <Card className="card-chart">
      <CardHeader>
        <h5 className="card-category">Bid Trends</h5>
        <CardTitle tag="h4">
          <i className="tim-icons icon-send text-danger" /> Last Bid : {symbolTf?.bid}
          <i
            className={
              "align-baseline tim-icons text-" + (trend?.trend ? "success icon-minimal-up" : "danger icon-minimal-down")
            }
            style={{ paddingLeft: "10px" }}
          />{" "}
          * {trend?.count} - {trend?.percentage} %
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div className="chart-area" style={{ height: "600px" }}>
          <Bar
            data={{
              labels: ["Bid Volume Percentage"],
              datasets: [
                {
                  label: symbolTf?.symbol,
                  fill: true,
                  backgroundColor: trend?.trend ? "#00f2c3" : "#fd5d93",
                  borderColor: trend?.trend ? "#fd5d93" : "#00f2c3",
                  borderWidth: 2,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  data: [trend?.percentage],
                },
              ],
            }}
            options={chartExample3.options}
          />
        </div>
      </CardBody>
    </Card>
  );
}
