import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    alertData: {

    }
};

export const notificationDataSlice = createSlice({
    name: "alertData",
    initialState,
    reducers: {
        setAlertData: (state, { payload }) => {

            state.alertData = payload;
        }
    },
});

export const { setAlertData } = notificationDataSlice.actions;
export default notificationDataSlice.reducer;
