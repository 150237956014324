import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    symbol: "LTC-USDT",
    tf : "1min"
};

export const curStateDataSlice = createSlice({
    name: "curState",
    initialState,
    reducers: {
        setSymbol: (state, { payload }) => {

            state.symbol = payload;
        },
        setTf: (state, { payload }) => {
            state.tf = payload;
        },
    },
});

export const { setSymbol,setTf } = curStateDataSlice.actions;
export default curStateDataSlice.reducer;
