import React, { useEffect, useState } from "react";
import { API } from "index";
import { setConfig } from "slices/configDataSlice";
// reactstrap components
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Row, Col, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setTradeSetting, setTradeSettingAll } from "slices/tradeSettingSlice";
import { Select, MenuItem } from "@mui/material";

function TradeSettings() {
  const dispatch = useDispatch();

  const [curbtnSymbol, setCurBTNSymbol] = useState("LTC-USDT");
  const {
    movingStopLoss,
    movingTakeProfit,
    fixedStopLoss,
    fixedTakeProfit,
    stopLossCount,
    leverage,
    jumpingCount,
    email,
    jumpingPercentage,
    potSize,
    maxOpenTrades,
    positionSide,
  } = useSelector((state) => state.tradeSetting);

  const changeSettings = (type, data) => {
    API.post("/changeSettings", {
      type: type,
      data: data,
    }).then((response) => {
      dispatch(setConfig(response.data.data));
    });
  };

  useEffect(() => {
    API.post("/getConfig", {
      email: email,
      symbol: curbtnSymbol,
    }).then((response) => {
      dispatch(setConfig(response.data.data.config));
      dispatch(setTradeSettingAll(response.data.data.tradeSetting));
    });
  }, []);

  const saveSettings = () => {
    API.post("/saveTradeSettings", {
      //id : user_id,
      movingStopLoss: parseFloat(movingStopLoss),
      movingTakeProfit: parseFloat(movingTakeProfit),
      fixedStopLoss: parseFloat(fixedStopLoss),
      fixedTakeProfit: parseFloat(fixedTakeProfit),
      stopLossCount: parseInt(stopLossCount),
      leverage: parseInt(leverage),
      jumpingCount: parseInt(jumpingCount),
      jumpingPercentage: parseFloat(jumpingPercentage),
      potSize: parseFloat(potSize),
      maxOpenTrades: parseFloat(maxOpenTrades),
      positionSide: parseFloat(positionSide),
      email: email,
    }).then((response) => {
      console.log(response.data);
    });
  };
  return (
    <Row>
      <Card>
        <CardHeader>
          <Row>
            <Col sm="3">
              <h5 className="title">Trade Settings</h5>
            </Col>
            <Col sm="6"></Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="pr-md-1" md="12">
              <FormGroup>
                <Row>
                  <Col md="3">
                    <Label for="stop_loss_percentage">Moving Stop Loss(%)</Label>
                    <Input
                      value={movingStopLoss}
                      className="form-control"
                      id="stop_loss_percentage"
                      onChange={(e) => {
                        dispatch(setTradeSetting({ type: "movingStopLoss", data: e.target.value }));
                      }}
                    />
                  </Col>
                  <Col md="3">
                    <Label for="stop_loss_percentage">Fixed Stop Loss($)</Label>
                    <Input
                      value={fixedStopLoss}
                      className="form-control"
                      id="stop_loss_fixed"
                      onChange={(e) => {
                        dispatch(setTradeSetting({ type: "fixedStopLoss", data: e.target.value }));
                      }}
                    />
                  </Col>

                  <Col md="3">
                    <Label for="stop_loss_percentage">Moving Take Profit(%)</Label>
                    <Input
                      value={movingTakeProfit}
                      className="form-control"
                      id="stop_loss_percentage"
                      onChange={(e) => {
                        dispatch(setTradeSetting({ type: "movingTakeProfit", data: e.target.value }));
                      }}
                    />
                  </Col>
                  <Col md="3">
                    <Label for="stop_loss_percentage">Fixed Take Profit($)</Label>
                    <Input
                      value={fixedTakeProfit}
                      className="form-control"
                      id="stop_loss_fixed"
                      onChange={(e) => {
                        dispatch(setTradeSetting({ type: "fixedTakeProfit", data: e.target.value }));
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Label for="stop_loss_percentage">Daily Stop Loss Count</Label>
                    <Input
                      value={stopLossCount}
                      className="form-control"
                      id="stop_loss_daily"
                      onChange={(e) => {
                        dispatch(setTradeSetting({ type: "stopLossCount", data: e.target.value }));
                      }}
                    />
                  </Col>
                  <Col md="3">
                    <Label for="stop_loss_percentage">Leverage</Label>
                    <Input
                      value={leverage}
                      className="form-control"
                      id="leverage"
                      onChange={(e) => {
                        dispatch(setTradeSetting({ type: "leverage", data: e.target.value }));
                      }}
                    />
                  </Col>

                  <Col md="3">
                    <Label for="stop_loss_percentage">Jumping counts to trigger signal</Label>
                    <Input
                      value={jumpingCount}
                      className="form-control"
                      id="jumping_count"
                      onChange={(e) => {
                        dispatch(setTradeSetting({ type: "jumpingCount", data: e.target.value }));
                      }}
                    />
                  </Col>
                  <Col md="3">
                    <Label for="stop_loss_percentage">Jumping percentage to trigger signal</Label>
                    <Input
                      value={jumpingPercentage}
                      className="form-control"
                      id="jumping_percentage"
                      onChange={(e) => {
                        dispatch(setTradeSetting({ type: "jumpingPercentage", data: e.target.value }));
                      }}
                    />
                  </Col>
                  {/* <Col md="6">
                            <Label for="stop_loss_percentage">
                              Leverage
                            </Label>
                            <Input value={10} className="form-control" id="leverage"/>
                          </Col> */}
                </Row>
                <Row>
                  <Col md="3">
                    <Label for="pot_size">Pot Size</Label>
                    <Input
                      value={potSize}
                      className="form-control"
                      id="pot_size"
                      onChange={(e) => {
                        dispatch(setTradeSetting({ type: "potSize", data: e.target.value }));
                      }}
                    />
                  </Col>
                  <Col md="3">
                    <Label for="max_open_trades">Max Open Trades</Label>
                    <Input
                      value={maxOpenTrades}
                      className="form-control"
                      id="max_open_trades"
                      type="number"
                      onChange={(e) => {
                        dispatch(setTradeSetting({ type: "maxOpenTrades", data: e.target.value }));
                      }}
                    />
                  </Col>

                  <Col md="3">
                    <Label for="position_side">Position Sides</Label>
                    <Select
                      value={positionSide}
                      className="form-control text-white"
                      id="position_side"
                      onChange={(e) => {
                        dispatch(setTradeSetting({ type: "positionSide", data: e.target.value }));
                      }}
                    >
                      <MenuItem value={1} color="white">
                        All Sides
                      </MenuItem>
                      <MenuItem value={2} color="white">
                        Only Long
                      </MenuItem>
                      <MenuItem value={3} color="white">
                        Only Short
                      </MenuItem>
                    </Select>
                  </Col>
                </Row>
                <Row style={{ float: "right" }}>
                  <Button
                    className="form-control btn-success"
                    style={{ width: "250px", marginRight: "100px" }}
                    onClick={() => {
                      saveSettings();
                    }}
                  >
                    Save
                  </Button>
                </Row>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Row>
  );
}

export default TradeSettings;
