import { Bar } from "react-chartjs-2";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { chartExample4 } from "variables/charts.js";
import { useEffect, useState } from "react";
import useSymbolTfContext from "contexts/SymbolTFContext";
import useBestAskBidContext from "contexts/BestAskBidContext";
export default function VolumeTrendsPanel(props) {
  const { symbolTf, setSymbolTf } = useSymbolTfContext();
  const { bestAB, setBestAB } = useBestAskBidContext();
  const [buySize, setBuySize] = useState();
  const [sellSize, setSellSize] = useState();
  useEffect(() => {
    if (bestAB.datasets[0].data) {
      const asks = bestAB.datasets[0].data.reverse();
      const lastAsk = asks[0];
      const bids = bestAB.datasets[1].data.reverse();
      const lastBid = bids[0];
      setBuySize(parseFloat(lastAsk));
      setSellSize(parseFloat(lastBid));
    }
  }, [bestAB]);

  return (
    <Card className="card-chart">
      <CardHeader style={{ minHeight: "98px" }}>
        <h5 className="card-category">Volume Trends</h5>
        <CardTitle tag="h4">
          <i className="tim-icons icon-send text-info" /> Volume Trends
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div className="chart-area" style={{ height: "600px" }}>
          <Bar
            data={{
              labels: ["Long Size", "Short Size"],
              datasets: [
                {
                  label: symbolTf?.symbol,
                  fill: true,
                  backgroundColor: ["rgba(53, 162, 235, 0.5)", "rgba(255, 99, 132, 0.5)"],
                  borderColor: ["rgba(53, 162, 235, 0.5)", "rgba(255, 99, 132, 0.5)"],
                  borderWidth: 2,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  data: [buySize, sellSize],
                },
              ],
            }}
            options={chartExample4.options}
          />
        </div>
      </CardBody>
    </Card>
  );
}
