
import React from "react";
import { Button, Dropdown, DropdownToggle, Badge } from "reactstrap";
import { ThemeContext, themes } from "contexts/ThemeContext";
import { backgroundColors } from "contexts/BackgroundColorContext";
import { useSelector } from "react-redux";
import { useState,useEffect } from "react";
// import TrendText from "./TrendText";
function FixedPlugin(props) {
  const [dropDownIsOpen, setdropDownIsOpen] = React.useState(true);
  const [vtrend, setVTrend] = useState({
    color : '',
    trend : '',
    amount : '',
    jumping : ''
  });
  const { klineData } = useSelector((state) => state.klineData);
  useEffect(() => {
    let klines = [...klineData].reverse();
    console.log(klines);
    if(klines.length > 0){
      let cur_trend = klines[0]['close'] >= klines[0]['open'];
      let count = 1;
      let size = klines[0]['amount'];
      for(let i= 1; i<klines.length;i++){
        if(cur_trend === (klines[i]['close'] >= klines[i]['open'])){
          count ++;
          size += klines[i]['amount'];
        }
        else{
          break
        }
      }
      let side_text = cur_trend ? 'Long' : 'Short';
      let jumping = count >= 5 ? 'Jumping' : '';
      let trend_string = side_text + ' * ' + count;
      let side_color = cur_trend ? 'rgba(53, 255, 235, 0.5)' : 'rgba(255, 99, 132, 0.5)';
      setVTrend({
        color : side_color,
        trend : trend_string,
        amount : Math.round(size*100)/100,
        jumping : jumping
      });
    }
  },[klineData])
  const handleClick = () => {
    setdropDownIsOpen(true);
  };
  return (
    <div className="fixed-plugin">
      <Dropdown isOpen={dropDownIsOpen} toggle={handleClick}>
        {/* <DropdownToggle tag="div">
          <i className="fa fa-cog fa-2x" />
        </DropdownToggle> */}
        <ul className="dropdown-menu show" style={{height:"250px"}}>
          <li className="header-title">Current Trends</li>
          <li className="adjustments-line">
            <div className="badge-colors text-center">
              <Badge
                color="primary"
                className={
                  props.bgColor === backgroundColors.primary ? "active" : ""
                }
                onClick={() => {
                  props.handleBgClick(backgroundColors.primary);
                }}
              />{" "}
              {/* <Badge
                color="info"
                className={
                  props.bgColor === backgroundColors.blue ? "active" : ""
                }
                onClick={() => {
                  props.handleBgClick(backgroundColors.blue);
                }}
              />{" "} */}
              <Badge
                color="success"
                className={
                  props.bgColor === backgroundColors.green ? "active" : ""
                }
                onClick={() => {
                  props.handleBgClick(backgroundColors.green);
                }}
              />{" "}
            </div>
          </li>
          <li className="adjustments-line text-center color-change">
              <h3 style={{color:vtrend.color}}>{vtrend.trend}</h3>
              <h4 style={{color:vtrend.color}}>{vtrend.amount}</h4>
              <h2 style={{color:vtrend.color}}>{vtrend.jumping}</h2>
              {/* <TrendText data={vtrend}/> */}
          </li>
          {/* <li className="button-container">
            <Button
              href="https://www.creative-tim.com/product/black-dashboard-react"
              color="primary"
              block
              className="btn-round"
            >
              Download Now
            </Button>
            <Button
              color="default"
              block
              className="btn-round"
              outline
              href="https://demos.creative-tim.com/black-dashboard-react/#/documentation/tutorial"
            >
              Documentation
            </Button>
          </li> */}
          {/* <li className="header-title">Want more components?</li>
          <li className="button-container">
            <Button
              href="https://www.creative-tim.com/product/black-dashboard-pro-react"
              className="btn-round"
              disabled
              block
              color="danger"
            >
              Get pro version
            </Button>
          </li> */}
        </ul>
      </Dropdown>
    </div>
  );
}

export default FixedPlugin;
