import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { useEffect } from 'react';
import { API } from 'index';
import { FiCommand } from 'react-icons/fi';
export default function HistoryTable(props) {
  const [tableData,setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    API.post('/getHistory',
    {
        symbol : props.symbol,
        tf : props.timeframe
    }).then((response) => {
        let data = [];
        response.data.data.forEach(element => {
            let timestamp = new Date(parseInt(element.timestamp)*1000);
            data.push({
                time : timestamp.toLocaleString(),
                open : element.open,
                close : element.close,
                high : element.high,
                low : element.low,
                amount : element.amount,
                volume : element.volume
            })
        });
        setTableData(data);
        setIsLoading(false);
    })
  },[props.symbol , props.timeframe])
  return (
    <>
      <TableContainer component={Paper} style={{ background: "transparent" ,display:(isLoading ? "none" : "flex")}} className='text-center'>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
            <TableCell className='text-white' align="center">Time</TableCell>
              <TableCell className='text-white' align="center">Open</TableCell>
              <TableCell className='text-white' align="center">Close</TableCell>
              <TableCell className='text-white' align="center">High</TableCell>
              <TableCell className='text-white' align="center">Low</TableCell>
              <TableCell className='text-white' align="center">Volume</TableCell>
              <TableCell className='text-white' align="center">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ background: "transparent" }}>
            {tableData.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="center" component="th" scope="row" className='text-white'>
                  {row.time}
                </TableCell>
                <TableCell align="center"  className='text-white'>{row.open.toString().slice(0, 6)}</TableCell>
                <TableCell align="center"  className='text-white'>{row.close.toString().slice(0, 6)}</TableCell>
                <TableCell align="center"  className='text-white'>{row.high.toString().slice(0, 6)}</TableCell>
                <TableCell align="center"  className='text-white'>{row.low.toString().slice(0, 6)}</TableCell>
                <TableCell align="center"  className='text-white'>{row.volume.toString().slice(0, 6)}</TableCell>
                <TableCell align="center"  className='text-white'>{row.amount.toString().slice(0, 6)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="w-100 justify-content-center text-center h-100" style={{ alignItems: "center", display: (isLoading ? "flex" : "none") }}>
        <FiCommand className="loading-icon" />
    </div>
      
    </>
  );
}