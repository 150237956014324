/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React ,{useState} from 'react';
// reactstrap components


import { API , baseURL } from 'index';
import { useHistory } from "react-router-dom";
import Logo from "assets/img/logo.png";
import { setAlertData } from "slices/notficationDataSlice";
import { useDispatch, useSelector } from "react-redux";
import HistoryTable from 'components/History/historyTable';
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    Input,
    Row,
    Col,
  } from "reactstrap";
import classNames from "classnames";
function History() {
  const [curSymbol , setCurSymbol] = useState('LTC-USDT');
  const [curTf , setCurTf] = useState('5min');
  const {symbols, tfs} = useSelector((state) => state.configData);
  const history = useHistory();
  

  return (
    <>
      <div className="content" style={{padding:"78px 30px 30px 30px "}}>
        
      <Row>
          <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="3">
                    <h5 className="card-category">History Table</h5>
                    <CardTitle tag="h2">{curSymbol} - {curTf}</CardTitle>
                  </Col>
                  <Col className="text-left" sm="3">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      {tfs.map((item, index) => (
                        <Button
                          tag="label"
                          className={classNames("btn-simple", {
                            active: curTf === item
                          })}
                          color={'success'}
                          id={index}
                          size="sm"
                          onClick={() => setCurTf(item)}
                          key={index}
                        >
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            {item}
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-single-02" />
                          </span>
                        </Button>
                      ))}
                    </ButtonGroup>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      {symbols.map((item, index) => (
                        <Button
                          tag="label"
                          className={classNames("btn-simple", {
                            active: curSymbol === item
                          })}
                          color={'info'}
                          id="0"
                          size="sm"
                          onClick={() => setCurSymbol(item)}
                          key={index}
                        >
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            {item}
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-single-02" />
                          </span>
                        </Button>
                      ))}
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody >
                <div className="chart-area" style={{height:"1000px",overflow:"scroll"}}>
                  <HistoryTable symbol={curSymbol} timeframe={curTf} />                  

                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default History;
