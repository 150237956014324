import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from "react-redux";
import { removeOrderData } from 'slices/orderDataSlice';
import OrderDetailModal from './OrderDetailModal';
import { useState } from 'react';
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function CurrentOrderTable(props) {
  const { orderData } = useSelector((state) => state.orderData);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalID, setModalID] = useState();


  const showDetail = (index) => {
    setModalData(orderData[index]);
    setModalID(index);
    setModal(true);
  }

  return (
    <>
      <TableContainer component={Paper} style={{ background: "transparent" }} className='text-center'>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell className='text-white'>Price(USDT)</TableCell>
              <TableCell className='text-white' align="right">Size</TableCell>
              <TableCell className='text-white' align="right">Leverage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ background: "transparent" }}>
            {orderData.map((order, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" className={'text-' + (order.side === 'long' ? 'success' : 'danger')} onClick={() => showDetail(index)}>
                  {order.price}
                </TableCell>
                <TableCell align="right" className={'text-' + (order.side === 'long' ? 'success' : 'danger')} onClick={() => showDetail(index)}>{order.size.toString().slice(0, 6)}</TableCell>
                <TableCell align="right" className={'text-' + (order.side === 'long' ? 'success' : 'danger')} onClick={() => showDetail(index)}>{order.leverage + 'x'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <OrderDetailModal open={modal} changeModal={setModal} modalData={modalData} dataID={modalID}/>
      
    </>
  );
}