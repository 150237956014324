import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function RecentTradeTable(props) {
  return (
    <TableContainer component={Paper} style={{ background: "transparent" }} className="text-center">
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className="text-white">Price(USDT)</TableCell>
            <TableCell className="text-white" align="right">
              Size
            </TableCell>
            <TableCell className="text-white" align="right">
              Time
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ background: "transparent" }}>
          {props.trades.map((trade, index) => (
            <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row" className={"text-" + (trade.side === "buy" ? "success" : "danger")}>
                {trade.price}
              </TableCell>
              <TableCell align="right" className="text-white">
                {trade.size.toString().slice(0, 6)}
              </TableCell>
              <TableCell align="right" className="text-white">
                {trade.time}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
