import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bestChartData: {
        labels: [],
        datasets: [
            {
                label: 'Best Ask',
                fill: true,
                // backgroundColor: gradientStroke,
                borderColor: "#1f8ef1",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: "#1f8ef1",
                pointBorderColor: "rgba(255,255,255,0)",
                pointHoverBackgroundColor: "#1f8ef1",
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: []
            },
            {
                label: 'Best Bid',
                fill: true,
                // backgroundColor: gradientStroke,
                borderColor: "##e14eca",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: "##e14eca",
                pointBorderColor: "rgba(255,255,255,0)",
                pointHoverBackgroundColor: "##e14eca",
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: []
            }
        ]
    }
};

export const bestChartDataSlice = createSlice({
    name: "bestChartData",
    initialState,
    reducers: {
        setBestData: (state, { payload }) => {

            state.bestChartData = payload;
        },
        setBestY: (state, { payload }) => {
            state.bestChartData.labels = payload;
        },
        setAskX: (state, { payload }) => {
            state.bestChartData.datasets[0].data = payload;
        },
        setBidX: (state, { payload }) => {
            state.bestChartData.datasets[1].data = payload;
        },
    },
});

export const { setBestData, setBestY, setAskX ,setBidX} = bestChartDataSlice.actions;
export default bestChartDataSlice.reducer;
