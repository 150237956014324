import { Line, Bar } from "react-chartjs-2";
import { FiCommand } from "react-icons/fi";
import { chartExample1 } from "variables/charts.js";
export default function LineChart(props) {
  return (
    <div className="chart-area">
      <Line data={props.data} options={chartExample1.options} />
      {/* <div
        className="w-100 justify-content-center text-center h-100"
        style={{ alignItems: "center", display: isLoading === true ? "flex" : "none" }}
      >
        <FiCommand className="loading-icon" />
      </div> */}
    </div>
  );
}
