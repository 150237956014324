import React, { useEffect, useState } from "react";
import { API } from "index";
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Row, Col, Label } from "reactstrap";

function AskBidSettings() {
  const [askRatio, setAskRatio] = useState(3);
  const [bidRatio, setBidRatio] = useState(5);
  const [abCount, setAbCount] = useState(3);
  const [abRatio, setAbRatio] = useState(3);
  const email = "aarontapi3@gmail.com";
  useEffect(() => {
    API.post("/getABSettings", {
      email: email,
      //   symbol: curbtnSymbol,
    }).then((response) => {
      const data = response.data.data;
      setAskRatio(data.askRatio);
      setBidRatio(data.bidRatio);
      setAbCount(data.abCount);
      setAbRatio(data.abRatio);
    });
  }, []);

  const saveABSettings = () => {
    API.post("/saveTradeSettings", {
      //id : user_id,
      askRatio: parseFloat(askRatio),
      bidRatio: parseFloat(bidRatio),
      abCount: parseFloat(abCount),
      abRatio: parseFloat(abRatio),
      email: email,
    }).then((response) => {
      console.log(response.data);
    });
  };

  return (
    <Row>
      <Card>
        <CardHeader>
          <Row>
            <Col sm="3">
              <h5 className="title">Ask/Bid Settings</h5>
            </Col>
            <Col sm="6"></Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="pr-md-1" md="12">
              <FormGroup>
                <Row>
                  <Col md="6">
                    <Label for="askRatio">Ask Growing Ratio</Label>
                    <Input
                      value={askRatio}
                      className="form-control"
                      id="askRatio"
                      type="number"
                      onChange={(e) => {
                        setAskRatio(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md="6">
                    <Label for="bidRatio">Bid Growing Ratio</Label>
                    <Input
                      value={bidRatio}
                      className="form-control"
                      id="bidRatio"
                      type="number"
                      onChange={(e) => {
                        setBidRatio(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Label for="abCount">Ask/Bid Counts</Label>
                    <Input
                      value={abCount}
                      className="form-control"
                      id="abCount"
                      type="number"
                      onChange={(e) => {
                        setAbCount(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md="6">
                    <Label for="abRatio">Trigger Ratio(Ask Ratio - Bid Ratio)</Label>
                    <Input
                      value={abRatio}
                      className="form-control"
                      id="abRatio"
                      type="number"
                      onChange={(e) => {
                        setAbRatio(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ float: "right" }}>
                  <Button
                    className="form-control btn-success"
                    style={{ width: "250px", marginRight: "100px" }}
                    onClick={() => {
                      saveABSettings();
                    }}
                  >
                    Save
                  </Button>
                </Row>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Row>
  );
}

export default AskBidSettings;
