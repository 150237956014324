import { createContext, useContext, useMemo, useState } from "react";

const PairsContext = createContext(null);

export const PairsProvider = ({ children }) => {
  const [coldPairs, setColdPairs] = useState([]);
  const [hotPairs, setHotPairs] = useState([]);

  const value = useMemo(
    () => ({
      coldPairs,
      hotPairs,
      setColdPairs,
      setHotPairs,
    }),
    [hotPairs, coldPairs]
  );
  return <PairsContext.Provider value={value}>{children}</PairsContext.Provider>;
};

const usePairsContext = () => {
  const context = useContext(PairsContext);

  if (context === undefined) {
    throw new Error("useEvent should be used inside EventProvider");
  }

  return context;
};

export default usePairsContext;
