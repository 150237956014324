/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";

import logo from "assets/img/react-logo.png";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import NotificationAlert from "react-notification-alert";
import { useSelector } from "react-redux";
import { SymbolTfProvider } from "contexts/SymbolTFContext";
import { OHLCProvider } from "contexts/OHLCContext";
import { BestAskBidProvider } from "contexts/BestAskBidContext";
import { RecentTradesProvider } from "contexts/RecentTradesContext";
import { PairsProvider } from "contexts/PairsContext";
var ps;

function Admin(props) {
  const location = useLocation();
  const mainPanelRef = React.useRef(null);
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );
  const [loggedIn, setLoggedIn] = React.useState();
  const { alertData } = useSelector((state) => state.alertData);
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current, {
        suppressScrollX: true,
      });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    if (window.localStorage.getItem("logged") === "loggedIn") {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });
  const notificationAlertRef = React.useRef(null);
  const showToast = (place, type, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlertRef) {
      notificationAlertRef.current.notificationAlert(options);
    }
  };
  React.useEffect(() => {
    if (alertData.place) {
      showToast(alertData.place, alertData.type, alertData.message);
    }
  }, [alertData]);
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />

      <BackgroundColorContext.Consumer>
        {({ color, changeColor }) => (
          <React.Fragment>
            <div className="wrapper">
              <OHLCProvider>
                {location.pathname === "/admin/dashboard" ? (
                  <Sidebar
                    routes={routes}
                    logo={{
                      outterLink: "https://www.creative-tim.com/",
                      text: "HydraTrade",
                      imgSrc: logo,
                    }}
                    toggleSidebar={toggleSidebar}
                  />
                ) : null}

                <div className="main-panel" ref={mainPanelRef} data={color}>
                  <SymbolTfProvider>
                    <PairsProvider>
                      <AdminNavbar
                        brandText={"hydratrade"}
                        toggleSidebar={toggleSidebar}
                        sidebarOpened={sidebarOpened}
                      />

                      <BestAskBidProvider>
                        <RecentTradesProvider>
                          <Switch>
                            {getRoutes(routes)}
                            <Redirect from="*" to="/admin/dashboard" />
                          </Switch>
                        </RecentTradesProvider>
                      </BestAskBidProvider>
                    </PairsProvider>
                  </SymbolTfProvider>
                  {/* {
                  // we don't want the Footer to be rendered on map page
                  location.pathname === "/admin/maps" ? null : <Footer fluid />
                } */}
                </div>
              </OHLCProvider>
            </div>
            {
              // we don't want the Footer to be rendered on map page
              // location.pathname === "/admin/dashboard" ? <FixedPlugin bgColor={color} handleBgClick={changeColor} /> : null
            }
          </React.Fragment>
        )}
      </BackgroundColorContext.Consumer>
    </>
  );
}

export default Admin;
