import * as React from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { API } from "index";
import { FiCommand } from "react-icons/fi";
export default function OrderTable(props) {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    API.post("/getOrder", {
      symbol: props.symbol,
      // tf : props.timeframe
    }).then((response) => {
      let data = [];
      let total_trades = 0;
      let closed_trades = 0;
      let win_trades = 0;
      let net_profit = 0;
      let profit_percentage = 0;
      response.data.data.forEach((element) => {
        // let timestamp = new Date(parseInt(element.time)*1000);

        if (element.status === 1) {
          closed_trades++;
          let is_buy = element.signal === "buy" ? 1 : -1;
          let profit = is_buy * (element.close_price - element.open_price) * element.amount * element.leverage;
          if (profit > 0) {
            win_trades++;
          }

          net_profit += profit;
        }
        let profit = 0;
        if (element.status === 1) {
          if (element.signal === "buy") {
            profit = (element.close_price - element.open_price) * element.amount * element.leverage;
          } else {
            profit = (element.open_price - element.close_price) * element.amount * element.leverage;
          }
        }
        let profit_color = "white";
        if (profit > 0) {
          profit_color = "success";
        }
        if (profit < 0) {
          profit_color = "danger";
        }
        data.push({
          open_time: element.open_time,
          signal: element.signal,
          open_price: element.open_price,
          amount: element.amount,
          type: element.type,
          symbol: element.symbol,
          tf: element.tf,
          leverage: element.leverage,
          exchange: element.exchange,
          status: element.status,
          close_time: element.close_time,
          close_price: element.close_price,
          profit: profit,
          profit_color: profit_color,
        });

        total_trades++;
      });
      setTableData(data);
      props.changeTotalData({
        total_trades: total_trades,
        win_trades: win_trades,
        net_profit: net_profit,
        closed_trades: closed_trades,
      });
      setIsLoading(false);
    });
  }, [props.symbol]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calculate the data to display for the current page
  const displayedRows = tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ background: "transparent", display: isLoading ? "none" : "flex", flexDirection:'column', height:'fit-content'}}
        className="text-center"
      >
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell className="text-white" align="center" width={'7%'}>
                Index
              </TableCell>
              <TableCell className="text-white" align="center" width={'10%'}>
                Type
              </TableCell>
              <TableCell className="text-white" align="center" width={'10%'}>
                Symbol
              </TableCell>
              <TableCell className="text-white" align="center" width={'30%'}>
                Time
              </TableCell>
              <TableCell className="text-white" align="center" width={'10%'}>
                Price
              </TableCell>
              <TableCell className="text-white" align="center" width={'10%'}>
                Contracts
              </TableCell>
              <TableCell className="text-white" align="center" width={'10%'}>
                Leverage
              </TableCell>
              <TableCell className="text-white" align="center" width={'10%'}>
                Profit
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <div style={{ overflow: 'auto', maxHeight: '650px' }}>
          <Table size="small" aria-label="a dense table">
            <TableBody style={{ background: "transparent"}}>
              {displayedRows.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow key={index * 2} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align="center" className="text-white" rowSpan={2} width={'7%'}>
                      {page*rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell align="center" className="text-white" width={'10%'}>
                      {row.status === 1 ? "Close " + (row.signal === "buy" ? "Long" : "Short") : ""}
                    </TableCell>
                    <TableCell align="center" className="text-white" width={'10%'}>
                      {row.symbol.toString()}
                    </TableCell>
                    <TableCell align="center" className="text-white" width={'30%'}>
                      {row.status === 1 ? row.close_time.toString() : ""}
                    </TableCell>
                    <TableCell align="center" className="text-white" width={'10%'}>
                      {row.status === 1 ? row.close_price.toString() : ""}
                    </TableCell>
                    <TableCell align="center" className="text-white" rowSpan={2} width={'10%'}>
                      {(Math.round(row.amount * 100) / 100).toString()}
                    </TableCell>
                    <TableCell align="center" className="text-white" rowSpan={2} width={'10%'}>
                      {row.leverage.toString()}
                    </TableCell>
                    <TableCell align="center" className={`text-${row.profit_color}`} rowSpan={2} width={'10%'}>
                      {row.profit === 0 ? "" : (Math.round(row.profit * 10000) / 10000).toString()}
                    </TableCell>
                  </TableRow>
                  <TableRow key={index * 2 + 1} >
                    <TableCell align="center" className="text-white" width={'10%'}>
                      {"Open " + (row.signal === "buy" ? "Long" : "Short")}
                    </TableCell>
                    <TableCell align="center" className="text-white" width={'10%'}>
                      {row.symbol.toString()}
                    </TableCell>
                    <TableCell align="center" className="text-white" width={'30%'}>
                      {row.open_time.toString()}
                    </TableCell>
                    <TableCell align="center" className="text-white" width={'10%'}>
                      {row.open_price.toString()}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
            </Table>
          </div>
          <Table size="small" aria-label="a dense table">
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25,50]}
                colSpan={8}
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{ color: "white" }}
                // Custom styling for pagination
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                sx={{
                  '.MuiTablePagination-selectIcon': {
                    color: 'white',
                  },
                  '.MuiTablePagination-displayedRows': {
                    color: 'white',
                  },
                  '.MuiTablePagination-actions': {
                    color: 'white',
                  },
                  '.MuiTablePagination-menuItem' : {
                    backgroundColor : '#1e1e2f!important'
                  }
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <div
        className="w-100 justify-content-center text-center h-100"
        style={{ alignItems: "center", display: isLoading ? "flex" : "none" }}
      >
        <FiCommand className="loading-icon" />
      </div>
    </>
  );
}
