import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  movingStopLoss: 2,
  movingTakeProfit: 2,
  fixedStopLoss: 2,
  fixedTakeProfit: 2,
  stopLossCount: 2,
  leverage: 10,
  jumpingCount: 5,
  email: "aarontapi3@gmail.com",
  jumpingPercentage: 3,
  potSize: 10000,
  maxOpenTrades: 10,
  positionSide: 1, // 1: all , 2: only long 3: only short
};

export const tradeSettingSlice = createSlice({
  name: "tradeSettings",
  initialState,
  reducers: {
    setTradeSetting: (state, { payload }) => {
      state[payload.type] = payload.data;
    },
    setTradeSettingAll: (state, { payload }) => {
      console.log(payload.positionSide);
      state.movingStopLoss = payload.movingStopLoss;
      state.movingTakeProfit = payload.movingTakeProfit;
      state.fixedStopLoss = payload.fixedStopLoss;
      state.fixedTakeProfit = payload.fixedTakeProfit;
      state.stopLossCount = payload.stopLossCount;
      state.leverage = payload.leverage;
      state.jumpingCount = payload.jumpingCount;
      state.jumpingPercentage = payload.jumpingPercentage;
      state.email = payload.email;
      state.potSize = payload.potSize;
      state.maxOpenTrades = payload.maxOpenTrades;
      state.positionSide = payload.positionSide;
    },
  },
});

export const { setTradeSetting, setTradeSettingAll } = tradeSettingSlice.actions;
export default tradeSettingSlice.reducer;
