import { API } from "index";
import { useEffect, useState } from "react";
import { Row, Label } from "reactstrap";
import React from "react";
import useSymbolTfContext from "contexts/SymbolTFContext";
import usePairsContext from "contexts/PairsContext";
export default function HotPairLine(props) {
  const [gainPairs, setGainPairs] = useState();
  const [losePairs, setLosePairs] = useState();
  const { symbolTf, setSymbolTF } = useSymbolTfContext();
  const { setColdPairs, setHotPairs } = usePairsContext();
  let hotPairs;
  let coldPairs;
  const fetchPairs = () => {
    API.get("/getHotPairs").then((res) => {
      const data = res.data.data;
      setGainPairs(data["topGainers"]);
      setLosePairs(data["topLosers"]);

      const updatedHotPairs = data["hotPairs"].map((cur_pair, index) => {
        const existingIndex = hotPairs?.findIndex((pair) => pair[0] === cur_pair[0]);
        if (existingIndex && existingIndex !== -1) {
          const existingPair = hotPairs[existingIndex];
          const ex_price = parseFloat(existingPair[2]);
          const cur_price = parseFloat(cur_pair[2]);
          const last_change = Math.round(((cur_price - ex_price) / ex_price) * 10000) / 100;
          const position_change = existingIndex - index;
          return [...cur_pair, last_change, position_change];
        }
        if (hotPairs && existingIndex === -1) {
          const existingPair = coldPairs?.find((pair) => pair[0] === cur_pair[0]);
          const ex_price = parseFloat(existingPair[2]);
          const cur_price = parseFloat(cur_pair[2]);
          const last_change = Math.round(((cur_price - ex_price) / ex_price) * 10000) / 100;
          const position_change = 999;
          return [...cur_pair, last_change, position_change];
        }

        return cur_pair;
      });

      const updatedColdPairs = data["coldPairs"].map((cur_pair, index) => {
        const existingIndex = coldPairs?.findIndex((pair) => pair[0] === cur_pair[0]);
        if (existingIndex && existingIndex !== -1) {
          const existingPair = coldPairs[existingIndex];
          const ex_price = parseFloat(existingPair[2]);
          const cur_price = parseFloat(cur_pair[2]);
          const last_change = Math.round(((cur_price - ex_price) / ex_price) * 10000) / 100;
          const position_change = existingIndex - index;
          return [...cur_pair, last_change, position_change];
        }
        if (coldPairs && existingIndex === -1) {
          const existingPair = hotPairs?.find((pair) => pair[0] === cur_pair[0]);
          const ex_price = parseFloat(existingPair[2]);
          const cur_price = parseFloat(cur_pair[2]);
          const last_change = Math.round(((cur_price - ex_price) / ex_price) * 10000) / 100;
          const position_change = 999;
          return [...cur_pair, last_change, position_change];
        }
        return cur_pair;
      });
      hotPairs = updatedHotPairs;
      coldPairs = updatedColdPairs;
      setHotPairs(updatedHotPairs);
      setColdPairs(updatedColdPairs);
      // setHotPairs(data["hotPairs"]);
      // setColdPairs(data["coldPairs"]);
    });
  };
  useEffect(() => {
    fetchPairs();

    // Fetch data every 1 minute
    const intervalId = setInterval(() => {
      fetchPairs();
    }, 60000); // 1 minute in milliseconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (symbolTf == null && gainPairs != null) {
      setSymbolTF({
        symbol: gainPairs[0][0],
        tf: "1min",
        price: gainPairs[0][2],
        ask: gainPairs[0][3],
        ask_qty: gainPairs[0][4],
        bid: gainPairs[0][5],
        bid_qty: gainPairs[0][6],
      });
    }
  }, [gainPairs]);
  return (
    <>
      <Row>
        <Label
          className="text-white mr-2 bg-darker p-2 br-10 text-center "
          style={{ minWidth: "100px", borderRadius: "10px" }}
        >
          {" "}
          Top Gaining{" "}
        </Label>
        {gainPairs?.map((pair, index) => (
          <React.Fragment key={`gainer_${index}`}>
            <Label
              className="mr-1 text-center align-content-center hotpair"
              style={{ color: "#faebd7" }}
              to="#"
              key={`label_${pair[0]}`}
              onClick={() => {
                setSymbolTF({ ...symbolTf, symbol: pair[0], price: pair[2] });
              }}
            >
              {pair[0]}
            </Label>
            <Label
              className="mr-2 text-center align-content-center text-success hotpair"
              to="#"
              key={pair.asset + pair.rate}
              onClick={() => {
                setSymbolTF({ ...symbolTf, symbol: pair[0], price: pair[2] });
              }}
            >
              {pair[1]}
            </Label>
          </React.Fragment>
        ))}
      </Row>
      <Row>
        <Label
          className="text-white mr-2 bg-darker p-2 br-10 text-center "
          title="Top Losing"
          style={{ minWidth: "100px", borderRadius: "10px" }}
        >
          Top Losing
        </Label>
        {losePairs?.map((pair, index) => (
          <React.Fragment key={`loser${index}`}>
            <Label
              className="mr-1 text-center align-content-center hotpair"
              style={{ color: "#faebd7" }}
              to="#"
              key={`label_${pair[0]}`}
              onClick={() => {
                setSymbolTF({ ...symbolTf, symbol: pair[0], price: pair[2] });
              }}
            >
              {pair[0]}
            </Label>
            <Label
              className="mr-2 text-center align-content-center text-danger hotpair"
              to="#"
              key={pair.asset + pair.rate}
              onClick={() => {
                setSymbolTF({ ...symbolTf, symbol: pair[0], price: pair[2] });
              }}
            >
              {pair[1]}
            </Label>
          </React.Fragment>
        ))}
      </Row>
    </>
  );
}
