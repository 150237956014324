import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import useSymbolTfContext from "contexts/SymbolTFContext";
import LineChart from "./chartComponents/LineChart";
import useBestAskBidContext from "contexts/BestAskBidContext";
export default function AskBidChart() {
  const { symbolTf, setSymbolTF } = useSymbolTfContext();
  const { bestAB, setBestAB } = useBestAskBidContext();
  return (
    <Col xs="12">
      <Card className="card-chart">
        <CardHeader>
          <Row>
            <Col className="text-left" sm="3">
              <h5 className="card-category">Best Ask/Best Bid</h5>
              <CardTitle tag="h2">
                {symbolTf?.symbol} - {symbolTf?.price} : {symbolTf?.tf} Chart
              </CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>{bestAB ? <LineChart data={bestAB} /> : null}</CardBody>
      </Card>
    </Col>
  );
}
