import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FiCommand } from "react-icons/fi";
import useSymbolTfContext from "contexts/SymbolTFContext";
import { useHistory } from "react-router-dom";
export default function PairTable(props) {
  const history = useHistory();
  const { symbolTf, setSymbolTF } = useSymbolTfContext();
  const handleClick = (pair) => {
    setSymbolTF({ ...symbolTf, symbol: pair[0], price: pair[2] });
    history.push("/admin/dashboard");
  };
  return (
    <>
      <h4 className="text-white">{props?.type == 0 ? "Hot Pairs" : "Cold Pairs"}</h4>
      {props?.pairs ? (
        <TableContainer
          component={Paper}
          style={{
            background: "transparent",
            display: props?.pairs ? "flex" : "none",
            height: "550px",
            overflowY: "scroll",
          }}
          className="text-center"
        >
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell className="text-white" align="center">
                  Index
                </TableCell>
                <TableCell className="text-white" align="center">
                  Symbol
                </TableCell>
                <TableCell className="text-white" align="center">
                  Price
                </TableCell>
                <TableCell className="text-white" align="center">
                  Percentage
                </TableCell>
                <TableCell className="text-white" align="center">
                  Ask Qty
                </TableCell>
                <TableCell className="text-white" align="center">
                  Bid Qty
                </TableCell>
                <TableCell className="text-white" align="center">
                  Last Change
                </TableCell>
                <TableCell className="text-white" align="center">
                  Position Change
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ background: "transparent" }}>
              {props?.pairs.map((item, index) => (
                <React.Fragment key={index}>
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align="center" className="text-white c-pointer" onClick={() => handleClick(item)}>
                      {index + 1}
                    </TableCell>
                    <TableCell align="center" className="text-white c-pointer" onClick={() => handleClick(item)}>
                      {item[0]}
                    </TableCell>
                    <TableCell align="center" className="text-white c-pointer" onClick={() => handleClick(item)}>
                      {item[2]}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`text-${props.color} c-pointer`}
                      onClick={() => handleClick(item)}
                    >
                      {item[1]}
                    </TableCell>
                    <TableCell align="center" className="text-success c-pointer" onClick={() => handleClick(item)}>
                      {item[4]}
                    </TableCell>
                    <TableCell align="center" className="text-danger c-pointer" onClick={() => handleClick(item)}>
                      {item[6]}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`text-${!item[7] ? "white" : item[7] >= 0 ? "success" : "danger"} c-pointer`}
                      onClick={() => handleClick(item)}
                    >
                      {item[7] ? `${item[7]}%` : "--"}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`text-${!item[8] ? "white" : item[8] >= 0 ? "success" : "danger"} c-pointer`}
                      onClick={() => handleClick(item)}
                    >
                      {item[8] !== undefined ? (
                        <>
                          {item[8] === 999 ? (
                            props.type === 1 ? (
                              <span style={{ marginLeft: "5px", color: "red" }}>→→</span>
                            ) : (
                              <span style={{ marginLeft: "5px", color: "green" }}>←←</span>
                            ) // Arrow left
                          ) : item[8] > 0 ? (
                            <span style={{ marginLeft: "5px", color: "green" }}>↑</span> // Arrow up
                          ) : item[8] < 0 ? (
                            <span style={{ marginLeft: "5px", color: "red" }}>↓</span> // Arrow down
                          ) : null}
                          {item[8] === 999 ? "" : item[8] !== 0 ? Math.abs(item[8]) : "--"}
                        </>
                      ) : (
                        "--"
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div
          className="w-100 justify-content-center text-center h-100"
          style={{ alignItems: "center", display: props?.pairs ? "none" : "flex" }}
        >
          <FiCommand className="loading-icon" />
        </div>
      )}
    </>
  );
}
