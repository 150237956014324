import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { useState } from "react";
import useRecentTradesContext from "contexts/RecentTradesContext";
import useSymbolTfContext from "contexts/SymbolTFContext";
import RecentTradeTable from "../tables/RecentTradeTable";
export default function RecentTradesPanel(props) {
  const { recentTrades, setRecentTrades } = useRecentTradesContext();
  const { symbolTf, setSymbolTf } = useSymbolTfContext();

  return (
    <Card className="card-chart" style={{ overflowY: "scroll", maxHeight: "728px" }}>
      <CardHeader>
        <h5 className="card-category">Recent Trades</h5>
        <CardTitle tag="h4">
          {recentTrades ? (
            <>
              <i
                className={"tim-icons icon-bell-55 text-" + (recentTrades[0]["side"] == "buy" ? "success" : "danger")}
              />{" "}
              {Math.round(parseFloat(recentTrades[0]["size"] * 10000)) / 10000} - BestAsk :
              {Math.round(parseFloat(symbolTf.ask) * 10000) / 10000} BestBid :{" "}
              {Math.round(parseFloat(symbolTf.bid) * 10000) / 10000}
            </>
          ) : null}
        </CardTitle>
      </CardHeader>
      <CardBody>{recentTrades ? <RecentTradeTable trades={recentTrades} /> : null}</CardBody>
    </Card>
  );
}
