import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import useSymbolTfContext from "contexts/SymbolTFContext";
import useOHLCContext from "contexts/OHLCContext";
import VolumeProfileChart from "./chartComponents/VolumeProfileChart";
import { parseData } from "utils/utils";
export default function VolumeChart() {
  const { symbolTf, setSymbolTF } = useSymbolTfContext();
  const { ohlc, setOhlc } = useOHLCContext();
  return (
    <Col xs="12">
      <Card className="card-chart">
        <CardHeader>
          <Row>
            <Col className="text-left" sm="3">
              <h5 className="card-category">Volume Chart</h5>
              <CardTitle tag="h2">
                {symbolTf?.symbol} - {symbolTf?.price} : {symbolTf?.tf} Chart
              </CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {ohlc ? <VolumeProfileChart type="svg" data={parseData(ohlc)} ratio={1} width={1500} /> : null}
        </CardBody>
      </Card>
    </Col>
  );
}
