/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import OrderTable from "components/Order/orderTable";
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button } from "reactstrap";
import OrderHeader from "components/Order/orderHeader";
import OrderFilter from "components/Order/orderFilter";
import { FiRefreshCcw } from "react-icons/fi";
function Order() {
  const [curSymbol, setCurSymbol] = useState("all");
  // const [curTf, setCurTf] = useState("5min");
  const [totalData, setTotalData] = useState({ total_trades: 100, win_trades: 0, closed_trades: 100, net_profit: 0 });
  const [pageSize, setPageSize] = useState(10);
  return (
    <>
      <div className="content" style={{ padding: "160px 100px 30px 100px" }}>
        <Row>
          <Col xs="12">
            <Card className="card-chart" style={{ padding: "20px" }}>
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="3">
                    <h5 className="card-category">Order Table</h5>
                    <CardTitle tag="h2">
                      {/* {curSymbol} - {curTf} */}
                      {curSymbol === "all" ? "All" : curSymbol}
                      <Button
                        onClick={() => window.location.reload()}
                        style={{ marginLeft: "20px", borderRadius: "10px" }}
                      >
                        <FiRefreshCcw />
                      </Button>
                    </CardTitle>
                  </Col>
                  {/* <OrderFilter changeSymbol={setCurSymbol} changePageSize={setPageSize} /> */}
                </Row>
                <Row>
                  <OrderHeader data={totalData} />
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area" style={{ height: "fit-content" }}>
                  <OrderTable symbol={curSymbol} changeTotalData={setTotalData} pageSize={pageSize} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Order;
