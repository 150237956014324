
import React from "react";
import { Button , Input } from "reactstrap";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addOrderData } from 'slices/orderDataSlice';
import { useHistory } from "react-router-dom";
import { setAlertData } from "slices/notficationDataSlice";
function PlaceOrder(props) {

    const [price,setPrice] = useState();
    const [size,setSize] = useState(0);
    const [leverage,setLeverage] = useState(5);
    const dispatch = useDispatch();
    const history = useHistory();
    

    const PlaceLong = () => {
        let logged = window.localStorage.getItem('logged');
        if(logged !== 'loggedIn'){
            dispatch(setAlertData({
                place : 'tr',
                type : 'warning',
                message : 'Please log in.'
            }));
            history.push('/admin/login');
            
            return;
        }

        if(price <= 0){
            dispatch(setAlertData({
                place : 'tr',
                type : 'warning',
                message : 'Price can not be under zero.'
            }));
            return;
        }
        if(size <= 0){
            dispatch(setAlertData({
                place : 'tr',
                type : 'warning',
                message : 'Amount can not be under zero.'
            }));
            return;
        }
        if(leverage <= 0){
            dispatch(setAlertData({
                place : 'tr',
                type : 'warning',
                message : 'Leverage can not be under zero.'
            }));
            return;
        }


        
        let time = new Date();
        time = time.toLocaleTimeString();
        dispatch(addOrderData({
            price : price,
            size : size,
            side : 'long',
            time : time,
            leverage : leverage,
        }));
    }

    const PlaceShort = () => {
        let logged = window.localStorage.getItem('logged');
        if(logged !== 'loggedIn'){
            dispatch(setAlertData({
                place : 'tr',
                type : 'warning',
                message : 'Please log in.'
            }));
            history.push('/admin/login');
            return;
        }

        if(price <= 0){
            dispatch(setAlertData({
                place : 'tr',
                type : 'warning',
                message : 'Price can not be under zero.'
            }));
            return;
        }
        if(size <= 0){
            dispatch(setAlertData({
                place : 'tr',
                type : 'warning',
                message : 'Amount can not be under zero.'
            }));
            return;
        }
        if(leverage <= 0){
            dispatch(setAlertData({
                place : 'tr',
                type : 'warning',
                message : 'Leverage can not be under zero.'
            }));
            return;
        }
        let time = new Date();
        time = time.toLocaleTimeString();
        dispatch(addOrderData({
            price : price,
            size : size,
            side : 'short',
            time : time,
            leverage : leverage,
        }));
    }
  
  return (
    <>
        <li className="header-title text-center"><h4>Place Order</h4></li>
        <li className="adjustments-line color-change d-flex justify-content-center items-center" style={{padding:'0px 20px 0px 20px'}}>              
            <p className="w-50" style={{fontSize:'15px'}}>Price</p>
            <Input value={price} className="w-50" onChange={(e) => setPrice(e.target.value)}></Input>
        </li>
        <li className="adjustments-line color-change d-flex justify-content-center items-center" style={{padding:'0px 20px 0px 20px'}}>              
            <p className="w-50" style={{fontSize:'15px'}}>Amount</p>
            <Input value={size} className="w-50" onChange={(e) => setSize(e.target.value)}></Input>
        </li>
        <li className="adjustments-line color-change d-flex justify-content-center items-center" style={{padding:'0px 20px 0px 20px'}}>              
            <p className="w-50" style={{fontSize:'15px'}}>Leverage</p>
            <Input value={leverage} className="w-50" onChange={(e) => setLeverage(e.target.value)}></Input>
        </li>
        <li className="adjustments-line text-center color-change d-flex justify-content-center items-center">              
            <Button color="success" onClick={()=>PlaceLong()}>
                Buy
            </Button>
            <Button color="danger" onClick={()=>PlaceShort()}>
                Sell
            </Button>
        </li>
    </>
  );
}

export default PlaceOrder;
