import { createContext, useContext, useMemo, useState } from "react";

const BestAskBidContext = createContext({
  labels: [],
  datasets: [
    {
      label: "Best Ask",
      fill: true,
      // backgroundColor: gradientStroke,
      borderColor: "#1f8ef1",
      borderWidth: 2,
      borderDash: [],
      borderDashOffset: 0.0,
      pointBackgroundColor: "#1f8ef1",
      pointBorderColor: "rgba(255,255,255,0)",
      pointHoverBackgroundColor: "#1f8ef1",
      pointBorderWidth: 20,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 15,
      pointRadius: 4,
      data: [],
    },
    {
      label: "Best Bid",
      fill: true,
      // backgroundColor: gradientStroke,
      borderColor: "##e14eca",
      borderWidth: 2,
      borderDash: [],
      borderDashOffset: 0.0,
      pointBackgroundColor: "##e14eca",
      pointBorderColor: "rgba(255,255,255,0)",
      pointHoverBackgroundColor: "##e14eca",
      pointBorderWidth: 20,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 15,
      pointRadius: 4,
      data: [],
    },
  ],
});

export const BestAskBidProvider = ({ children }) => {
  const [bestAB, setBestAB] = useState({
    labels: [],
    datasets: [
      {
        label: "Best Ask",
        fill: true,
        // backgroundColor: gradientStroke,
        borderColor: "#1f8ef1",
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: "#1f8ef1",
        pointBorderColor: "rgba(255,255,255,0)",
        pointHoverBackgroundColor: "#1f8ef1",
        pointBorderWidth: 20,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 15,
        pointRadius: 4,
        data: [],
      },
      {
        label: "Best Bid",
        fill: true,
        // backgroundColor: gradientStroke,
        borderColor: "##e14eca",
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: "##e14eca",
        pointBorderColor: "rgba(255,255,255,0)",
        pointHoverBackgroundColor: "##e14eca",
        pointBorderWidth: 20,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 15,
        pointRadius: 4,
        data: [],
      },
    ],
  });

  const value = useMemo(
    () => ({
      bestAB,
      setBestAB,
    }),
    [bestAB, setBestAB]
  );
  return <BestAskBidContext.Provider value={value}>{children}</BestAskBidContext.Provider>;
};

const useBestAskBidContext = () => {
  const context = useContext(BestAskBidContext);

  if (context === undefined) {
    throw new Error("useEvent should be used inside EventProvider");
  }

  return context;
};

export default useBestAskBidContext;
