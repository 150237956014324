import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    orderData: [

    ]
};

export const orderDataSlice = createSlice({
    name: "orderData",
    initialState,
    reducers: {
        setOrderData: (state, { payload }) => {

            state.orderData = payload;
        },
        addOrderData : (state,{payload}) => {
            state.orderData.push(payload);
        },
        removeOrderData : (state,{payload}) => {
            state.orderData.splice(payload.index,1);
        },
    },
});

export const { setOrderData , addOrderData , removeOrderData } = orderDataSlice.actions;
export default orderDataSlice.reducer;
