import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tradeData: [

    ]
};

export const tradeDataSlice = createSlice({
    name: "tradeData",
    initialState,
    reducers: {
        setTradeData: (state, { payload }) => {

            state.tradeData = payload;
        }
    },
});

export const { setTradeData } = tradeDataSlice.actions;
export default tradeDataSlice.reducer;
