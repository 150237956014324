import React, { useEffect, useState } from "react";
import { API } from "index";
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Row, Col, Label } from "reactstrap";

function PositionSettings() {
  const [positionDepth, setPositionDepth] = useState(3);
  const [positionChange, setPositionChange] = useState(10);
  const email = "aarontapi3@gmail.com";
  useEffect(() => {
    API.post("/getPositionSettings", {
      email: email,
      //   symbol: curbtnSymbol,
    }).then((response) => {
      const data = response.data.data;
      setPositionDepth(data.positionDepth);
      setPositionChange(data.positionChange);
    });
  }, []);

  const savePositionSettings = () => {
    API.post("/saveTradeSettings", {
      //id : user_id,
      positionDepth: parseFloat(positionDepth),
      positionChange: parseFloat(positionChange),
      email: email,
    }).then((response) => {
      console.log(response.data);
    });
  };

  return (
    <Row>
      <Card>
        <CardHeader>
          <Row>
            <Col sm="3">
              <h5 className="title">Position Settings</h5>
            </Col>
            <Col sm="6"></Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="pr-md-1" md="12">
              <FormGroup>
                <Row>
                  <Col md="6">
                    <Label for="positionDepth">Position Depth</Label>
                    <Input
                      value={positionDepth}
                      className="form-control"
                      id="positionDepth"
                      type="number"
                      onChange={(e) => {
                        setPositionDepth(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md="6">
                    <Label for="positionChange">Position Change To Trigger</Label>
                    <Input
                      value={positionChange}
                      className="form-control"
                      id="positionChange"
                      type="number"
                      onChange={(e) => {
                        setPositionChange(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ float: "right" }}>
                  <Button
                    className="form-control btn-success"
                    style={{ width: "250px", marginRight: "100px" }}
                    onClick={() => {
                      savePositionSettings();
                    }}
                  >
                    Save
                  </Button>
                </Row>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Row>
  );
}

export default PositionSettings;
