import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    symbols: [],
    tfs : []
};

export const configDataSlice = createSlice({
    name: "config",
    initialState,
    reducers: {
        setConfig: (state, { payload }) => {

            state.symbols = payload.symbols;
            state.tfs = payload.tfs;
        },
        setSymbols: (state, { payload }) => {

            state.symbols = payload;
        },
        setTfs: (state, { payload }) => {
            state.tfs = payload;
        },
    },
});

export const { setConfig,setSymbols,setTfs } = configDataSlice.actions;
export default configDataSlice.reducer;
