import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from "reactstrap";
import { useDispatch } from 'react-redux';
import { removeOrderData } from 'slices/orderDataSlice';
import { setAlertData } from 'slices/notficationDataSlice';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: '#27293d',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function OrderDetailModal(props) {
//   const [open, setOpen] = React.useState(props.open ? props.open : false);
  const handleClose = () => props.changeModal(false);
  const dispatch = useDispatch();
  
  const closeOrder = (index) => {
    dispatch(removeOrderData(index));
    dispatch(setAlertData({
      place : 'tr',
      type : 'success',
      message : 'Successfully cancelled order.'
    }))
    handleClose();
  }

  
  return (
    <div>
      <Modal
        open={props.open ? props.open : false}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            {props.modalData.size ? 
            <TableContainer component={Paper} style={{ background: "transparent" }} className='text-center'>
                <Table size="large" >
                <TableHead>
                    <TableRow>
                    <TableCell className='text-white'>Price(USDT)</TableCell>
                    <TableCell className='text-white' align="center">Size</TableCell>
                    <TableCell className='text-white' align="center">Leverage</TableCell>
                    <TableCell className='text-white' align="center">Time</TableCell>
                    <TableCell className='text-white' align="center">Side</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody style={{ background: "transparent" }}>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" className={'text-' + (props.modalData.side === 'long' ? 'success' : 'danger')} >
                        {props.modalData.price}
                        </TableCell>
                        <TableCell align="center" className={'text-' + (props.modalData.side === 'long' ? 'success' : 'danger')} >{props.modalData.size.toString().slice(0, 6)}</TableCell>
                        <TableCell align="center" className={'text-' + (props.modalData.side === 'long' ? 'success' : 'danger')} >{props.modalData.leverage + 'x'}</TableCell>
                        <TableCell align="center" className={'text-' + (props.modalData.side === 'long' ? 'success' : 'danger')} >{props.modalData.time}</TableCell>
                        <TableCell align="center" className={'text-' + (props.modalData.side === 'long' ? 'success' : 'danger')} >{props.modalData.side.toUpperCase()}</TableCell>
                    </TableRow>
                </TableBody>
                </Table>
            </TableContainer>
            : null }
            <Button className="danger" color="danger" style={{float:'right'}} onClick={()=>closeOrder(props.dataID)}>Close</Button>
        </Box>
      </Modal>
    </div>
  );
}
