import { configureStore } from '@reduxjs/toolkit'
import bigChartDataSlice from 'slices/bigChartDataSlice'
import klineDataSlice from 'slices/klineDataSlice'
import tradeDataSlice from 'slices/tradeDataSlice'
import bestChartDataSlice from 'slices/bestChartDataSlice'
import configDataSlice  from 'slices/configDataSlice'
import orderDataSlice from 'slices/orderDataSlice'
import notficationDataSlice from 'slices/notficationDataSlice'
import tradeSettingSlice from './slices/tradeSettingSlice'
import curStateDataSlice from './slices/curStateDataSlice'
export default configureStore({
    reducer: {
        bigChartData: bigChartDataSlice,
        klineData: klineDataSlice,
        tradeData : tradeDataSlice,
        bestChartData : bestChartDataSlice,
        configData : configDataSlice,
        orderData : orderDataSlice,
        alertData : notficationDataSlice,
        tradeSetting : tradeSettingSlice,
        curState : curStateDataSlice,
    },
})