import React from "react";
import PropTypes from "prop-types";

import { scaleTime } from "d3-scale";
import { utcMinute } from "d3-time";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import { ChartCanvas, Chart } from "react-stockcharts";
import { BarSeries, VolumeProfileSeries, CandlestickSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { CrossHairCursor, MouseCoordinateX, MouseCoordinateY, EdgeIndicator } from "react-stockcharts/lib/coordinates";
import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
import { OHLCTooltip } from "react-stockcharts/lib/tooltip";
import { last, timeIntervalBarWidth } from "react-stockcharts/lib/utils";
import { change } from "react-stockcharts/lib/indicator";

export default function VolumeProfileChart(props) {
  // const { type, width, data, ratio } = this.props;
  const changeCalculator = change();
  // const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor((d) => d.date);
  const type = props?.type || "svg";
  const width = props?.width || 1920;
  const calculatedData = changeCalculator(props.data);
  const ratio = props?.data || 1;
  const xAccessor = (d) => d.date;
  // const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(calculatedData);
  const start = xAccessor(last(props.data));
  const end = xAccessor(props.data[Math.max(0, props.data.length - 100)]);

  const xExtents = [start, end];
  // console.log(data);
  return (
    <ChartCanvas
      height={400}
      ratio={props.ratio}
      width={props.width}
      margin={{ left: 50, right: 50, top: 10, bottom: 30 }}
      type={props.type}
      seriesName="MSFT"
      data={props.data}
      xAccessor={xAccessor}
      xScale={scaleTime()}
      xExtents={xExtents}
    >
      <Chart id={1} yExtents={[(d) => d.volume]}>
        <XAxis axisAt="bottom" orient="bottom" />
        <YAxis axisAt="left" orient="left" ticks={5} />
        <MouseCoordinateY at="right" orient="right" displayFormat={format(".2f")} />

        <BarSeries
          yAccessor={(d) => d.volume}
          widthRatio={0.95}
          opacity={1}
          fill={(d) => (d.close > d.open ? "#1d8cf8" : "#f5365c")}
          width={timeIntervalBarWidth(utcMinute)}
        />
      </Chart>

      {/* <CrossHairCursor /> */}
    </ChartCanvas>
  );
}

// CandleStickChart.propTypes = {
//   data: PropTypes.array.isRequired,
//   width: PropTypes.number.isRequired,
//   ratio: PropTypes.number.isRequired,
//   type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
// };

// CandleStickChart.defaultProps = {
//   type: "svg",
// };
// CandleStickChart = fitWidth(CandleStickChart);
