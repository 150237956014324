export function parseData(ohlc) {
  let parsedData = [];
  ohlc.map((item, index) => {
    const date = new Date(item[0] * 1000);
    const dateString = date.toISOString();
    const open = parseFloat(item[1]);
    const high = parseFloat(item[2]);
    const low = parseFloat(item[3]);
    const close = parseFloat(item[4]);
    const volume = parseFloat(item[6]);
    parsedData.push({
      date: date,
      open: open,
      high: high,
      low: low,
      close: close,
      volume: volume,
      split: "",
      dividend: "",
      absoluteChange: "",
      percentChange: "",
    });
  });
  return parsedData;
}

export function parseTrades(trades) {
  let parsedData = [];
  trades.map((item, index) => {
    const date = new Date(item[2] * 1000);
    const dateString = date.toISOString();
    const side = item[3] == "s" ? "sell" : "buy";
    const size = parseFloat(item[0]) * parseFloat(item[1]);
    const price = parseFloat(item[0]);
    parsedData.push({
      time: dateString,
      side: side,
      size: size,
      price: price,
    });
  });
  return parsedData;
}

export function getCurrentTrend(data) {
  let curTrend;
  let count = 0;
  let percentage = 0;
  let ohlc = [...data];
  ohlc = ohlc.reverse();
  console.log(ohlc);
  for (let i = 0; i < ohlc.length; i++) {
    const open = parseFloat(ohlc[i][1]);
    const close = parseFloat(ohlc[i][4]);
    const trend = close > open ? true : false;
    if (curTrend == undefined) {
      curTrend = trend;
      count++;
    } else {
      if (trend == curTrend) {
        count++;
      } else {
        const firstClose = parseFloat(ohlc[0][4]);
        percentage = Math.round(((firstClose - close) / close) * 10000) / 100;
        percentage = Math.abs(percentage);
        break;
      }
    }
  }
  let trendData = {
    trend: curTrend,
    count: count,
    percentage: percentage,
  };
  return trendData;
}

export function getABTrend(data) {
  let curTrend;
  let count = 0;
  let i = 0;
  let percentage = 0;
  data = data.reverse();
  for (let i = 1; i < data.length; i++) {
    const exValue = parseFloat(data[i]);
    const value = parseFloat(data[i - 1]);
    const trend = value > exValue ? true : false;
    if (curTrend == undefined) {
      curTrend = trend;
      count++;
    } else {
      if (trend == curTrend) {
        count++;
      } else {
        const firstValue = parseFloat(data[0]);
        percentage = Math.round(((firstValue - value) / value) * 10000) / 100;
        // percentage = Math.abs(percentage);
        break;
      }
    }
  }
  let trendData = {
    trend: curTrend,
    count: count,
    percentage: percentage,
  };
  return trendData;
}

export function getPercentageTrend(asks, bids) {
  let curTrend;
  let count = 0;
  let i = 0;
  let percentage = 0;
  asks = asks.reverse();
  bids = bids.reverse();
  for (let i = 0; i < asks.length; i++) {
    const ask = parseFloat(asks[i]);
    const bid = parseFloat(bids[i]);
    const trend = ask > bid ? true : false;
    if (curTrend == undefined) {
      curTrend = trend;
      count++;
    } else {
      if (trend == curTrend) {
        count++;
      } else {
        const firstValue = (parseFloat(asks[0]) - parseFloat(bids[0])) / parseFloat(bids[0]);
        const curValue = (parseFloat(asks[i - 1]) - parseFloat(bids[i - 1])) / parseFloat(bids[i - 1]);
        percentage = Math.round(((firstValue - curValue) / curValue) * 10000) / 100;
        // percentage = Math.abs(percentage);
        break;
      }
    }
  }
  let trendData = {
    trend: curTrend,
    count: count,
    percentage: percentage,
  };
  return trendData;
}
