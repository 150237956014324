import { Row, Col } from "reactstrap";
import { API } from "index";
import axios from "axios";
import PriceChart from "components/Dashboard/charts/PriceChart";
import VolumeChart from "components/Dashboard/charts/VolumeChart";
// import MarketTrendChart from "components/Dashboard/charts/MarketTrendChart";
import AskBidChart from "components/Dashboard/charts/AskBid";
import RecentTradePanel from "components/Dashboard/panels/RecenTradesPanel";
import TrendsPanel from "components/Dashboard/panels/TrendsPanel";
import useSymbolTfContext from "contexts/SymbolTFContext";
import useOHLCContext from "contexts/OHLCContext";
import useBestAskBidContext from "contexts/BestAskBidContext";
import useRecentTradesContext from "contexts/RecentTradesContext";
import { parseTrades } from "utils/utils";
import { useEffect } from "react";

const endpoint = "wss://ws.kraken.com/v2";
const socket = new WebSocket(`${endpoint}`);

function Dashboard(props) {
  const { symbolTf, setSymbolTF } = useSymbolTfContext();
  const { ohlc, setOhlc } = useOHLCContext();
  const { bestAB, setBestAB } = useBestAskBidContext();
  const { recentTrades, setRecentTrades } = useRecentTradesContext();
  let ex_ticker;
  socket.onopen = () => {
    console.log("websocket established");
  };

  socket.onclose = (event) => {
    console.log("websocket closed");
  };
  socket.onerror = (event) => {};

  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    if (data.channel == "ticker") {
      const last = data.data[0]["last"];
      const ask = data.data[0]["ask"];
      const ask_qty = data.data[0]["ask_qty"];
      const bid = data.data[0]["bid"];
      const bid_qty = data.data[0]["bid_qty"];
      const high = data.data[0]["high"];
      const low = data.data[0]["low"];
      const date = new Date();
      const time = date.toLocaleTimeString();
      if (symbolTf.price != last) {
        setSymbolTF({
          symbol: symbolTf.symbol,
          tf: symbolTf.tf,
          price: last,
          ask: ask,
          ask_qty: ask_qty,
          bid: bid,
          bid_qty: bid_qty,
        });
      }
      let newAB = { ...bestAB };
      newAB.datasets[0].data.push(parseFloat(ask) * parseFloat(ask_qty));
      newAB.datasets[1].data.push(parseFloat(bid) * parseFloat(bid_qty));
      newAB.labels.push(time);
      setBestAB(newAB);
    }
    if (data.channel == "trade") {
      let trades = [...recentTrades];
      const date = new Date(data.data[0]["timestamp"]);
      const dateString = date.toISOString();
      const side = data.data[0]["side"];
      const size = data.data[0]["qty"] * data.data[0]["price"];
      const price = data.data[0]["price"];
      const newTrade = {
        time: dateString,
        price: price,
        size: size,
        side: side,
      };
      trades.pop();
      trades.unshift(newTrade);
      setRecentTrades(trades);
    }
    if (data.channel == "ohlc") {
      let newOhlc = [...ohlc];
      const time = Date.parse(data.data[0]["timestamp"]) / 1000;
      const open = data.data[0]["open"].toString();
      const high = data.data[0]["high"].toString();
      const low = data.data[0]["low"].toString();
      const close = data.data[0]["close"].toString();
      const vwap = data.data[0]["vwap"].toString();
      const volume = data.data[0]["volume"].toString();
      const count = data.data[0]["trades"].toString();
      let newData = [time, open, high, low, close, vwap, volume, count];
      newOhlc.unshift(newData);
      setOhlc(newOhlc);
    }
  };

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  useEffect(() => {
    if (!socket.CONNECTING || socket.CLOSED || socket.OPEN) {
      if (symbolTf) {
        const validSymbol = symbolTf.symbol + "USD";
        sleep(3000).then((res) => {
          let ticker = symbolTf.symbol + "/USD";
          const tf = symbolTf.tf.replace("min", "");
          socket.send(
            JSON.stringify({
              method: "subscribe",
              params: {
                channel: "ticker",
                symbol: [ticker],
              },
            })
          );
          socket.send(
            JSON.stringify({
              method: "subscribe",
              params: {
                channel: "trade",
                symbol: [ticker],
              },
            })
          );
          socket.send(
            JSON.stringify({
              method: "subscribe",
              params: {
                channel: "ohlc",
                symbol: [ticker],
                interval: parseInt(tf),
              },
            })
          );
          if (ex_ticker) {
            socket.send(
              JSON.stringify({
                method: "unsubscribe",
                params: {
                  channel: "ticker",
                  symbol: [ex_ticker],
                },
              })
            );
            socket.send(
              JSON.stringify({
                method: "unsubscribe",
                params: {
                  channel: "trade",
                  symbol: [ticker],
                },
              })
            );
            socket.send(
              JSON.stringify({
                method: "unsubscribe",
                params: {
                  channel: "ohlc",
                  symbol: [ticker],
                  interval: parseInt(tf),
                },
              })
            );
            let newAB = { ...bestAB };
            newAB.datasets[0].data = [];
            newAB.datasets[1].data = [];
            setBestAB(newAB);
          }
          ex_ticker = validSymbol;
        });

        axios({
          method: "get",
          url: `https://api.kraken.com/0/public/OHLC?pair=${validSymbol}&interval=${symbolTf.tf.replace("min", "")}`,
          headers: {
            Accept: "application/json",
          },
          withCredentials: false,
        }).then((res) => {
          setOhlc(res.data.result[validSymbol]);
        });

        axios({
          method: "get",
          url: `https://api.kraken.com/0/public/Trades?pair=${validSymbol}`,
          headers: {
            Accept: "application/json",
          },
          withCredentials: false,
        }).then((res) => {
          const trades = res.data.result[validSymbol];
          const end = trades.length > 50 ? 50 : trades.length;
          const reverse = trades.reverse();
          const recent = reverse.slice(0, end);
          setRecentTrades(parseTrades(recent));
        });
      }
    }
  }, [symbolTf]);
  return (
    <>
      <div className="content">
        <Row>
          <PriceChart />
        </Row>
        <Row>
          <VolumeChart />
        </Row>

        <Row>
          <AskBidChart />
        </Row>
        <Row>
          <Col lg="4">
            <RecentTradePanel />
          </Col>
          <Col lg="8">
            <TrendsPanel />
          </Col>
        </Row>
        <Row></Row>
      </div>
    </>
  );
}

export default Dashboard;
