import React from "react";
import { Row, Col } from "reactstrap";
import PairTable from "./PairTable";
import usePairsContext from "contexts/PairsContext";
function MarketArea() {
  const { coldPairs, hotPairs } = usePairsContext();
  return (
    <Row className="p-4">
      <Col xs="6" className="p-1">
        <PairTable pairs={hotPairs} type={0} color={"success"} />
      </Col>
      <Col xs="6" className="p-1">
        <PairTable pairs={coldPairs} type={1} color={"danger"} />
      </Col>
    </Row>
  );
}

export default MarketArea;
