import React from "react";
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from "reactstrap";
import MarketArea from "components/Market/MarketArea";
function Market() {
  return (
    <>
      <div className="content" style={{ padding: "160px 100px 30px 100px" }}>
        <Row>
          <Col xs="12">
            <Card className="card-chart" style={{ padding: "20px" }}>
              <CardHeader>
                <Row>
                  {/* <Col className="text-left" sm="3">
                    <h5 className="card-category">Market Trend</h5>
                                  </Col> */}
                  <CardTitle>Market Trend</CardTitle>
                </Row>
                <Row></Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area" style={{ height: "600px" }}>
                  <MarketArea />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Market;
