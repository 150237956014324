import React, { useEffect, useState } from "react";
import { API } from "index";
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Row, Col, Label } from "reactstrap";

function SafetySettings() {
  const [dailyWarning, setDailyWarning] = useState(3);
  const [dailyStop, setDailyStop] = useState(5);
  const [threeDayStop, setThreeDayStop] = useState(5);
  const [weeklyStop, setWeeklyStop] = useState(3);
  const email = "aarontapi3@gmail.com";
  useEffect(() => {
    API.post("/getSafetySettings", {
      email: email,
      //   symbol: curbtnSymbol,
    }).then((response) => {
      const data = response.data.data;
      setDailyWarning(data.dailyWarning);
      setDailyStop(data.dailyStop);
      setThreeDayStop(data.threeDayStop);
      setWeeklyStop(data.weeklyStop);
    });
  }, []);

  const saveSafetySettings = () => {
    API.post("/saveTradeSettings", {
      //id : user_id,
      dailyWarning: parseFloat(dailyWarning),
      dailyStop: parseFloat(dailyStop),
      threeDayStop: parseFloat(threeDayStop),
      weeklyStop: parseFloat(weeklyStop),
      email: email,
    }).then((response) => {
      console.log(response.data);
    });
  };

  return (
    <Row>
      <Card>
        <CardHeader>
          <Row>
            <Col sm="3">
              <h5 className="title">Safety Settings</h5>
            </Col>
            <Col sm="6"></Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="pr-md-1" md="12">
              <FormGroup>
                <Row>
                  <Col md="6">
                    <Label for="dailyWarning">Daily Warning(%)</Label>
                    <Input
                      value={dailyWarning}
                      className="form-control"
                      id="dailyWarning"
                      type="number"
                      onChange={(e) => {
                        setDailyWarning(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md="6">
                    <Label for="dailyStop">Daily Stop(%)</Label>
                    <Input
                      value={dailyStop}
                      className="form-control"
                      id="dailyStop"
                      type="number"
                      onChange={(e) => {
                        setDailyStop(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Label for="threeDayStop">Three Day Stop(%)</Label>
                    <Input
                      value={threeDayStop}
                      className="form-control"
                      id="threeDayStop"
                      type="number"
                      onChange={(e) => {
                        setThreeDayStop(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md="6">
                    <Label for="weeklyStop">Weekly Stop(%)</Label>
                    <Input
                      value={weeklyStop}
                      className="form-control"
                      id="weeklyStop"
                      type="number"
                      onChange={(e) => {
                        setWeeklyStop(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ float: "right" }}>
                  <Button
                    className="form-control btn-success"
                    style={{ width: "250px", marginRight: "100px" }}
                    onClick={() => {
                      saveSafetySettings();
                    }}
                  >
                    Save
                  </Button>
                </Row>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Row>
  );
}

export default SafetySettings;
