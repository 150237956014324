/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React ,{useState} from 'react';
// reactstrap components
import { Card, CardHeader, CardBody, Row, Col,InputGroup,Input,Label, Button,Alert,
  UncontrolledAlert,} from "reactstrap";

import { API , baseURL } from 'index';
import { useHistory } from "react-router-dom";
import Logo from "assets/img/logo.png";
import { setAlertData } from "slices/notficationDataSlice";
import { useDispatch } from 'react-redux';
function Login() {
  const [email , setEmail] = useState('');
  const [password , setPassword] = useState('');
  // const notificationAlertRef = React.useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  

  const login = () => {

    API.post('/login',{
        email : email,
        password : password
    }).then((response) => {
        let result = response.data.data;
        // console.log(result);
        if(result == 'success')
        {
            localStorage.setItem('logged','loggedIn');
            dispatch(setAlertData({
              place : 'tr',
              type : 'success',
              message : 'Welcome to HydraTrade.'
            }));
            
            // showToast('tl','success','Welcome to HydraTrade.');
            // window.location.href = baseURL + '/admin/dashboard';
            history.push('/admin/dashboard');
        }
        else if(result == 'fail0')
        {
            // showToast('tl','danger','User not exists. Please register first');
            dispatch(setAlertData({
              place : 'tr',
              type : 'danger',
              message : 'User not exists. Please register first.'
            }));
            history.push('/admin/register/');       
        }
        else if(result == 'fail1')
        {
          dispatch(setAlertData({
            place : 'tr',
            type : 'warning',
            message : 'User not verified. Please verify your account in your Email.'
          }));
            // showToast('tl','warning','User not verified. Please verify your account in your Email')  ;      
        }
        else if(result == 'fail2')
        {
          dispatch(setAlertData({
            place : 'tr',
            type : 'danger',
            message : 'Password incorrect. Please try again.'
          }));  
          // showToast('tl','danger','Password incorrect. Please try again');
        }
        // console.log(res.data);
    })
    
  }

  return (
    <>
      <div className="content" style={{padding:"78px 30px 30px 30px "}}>
        
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader style={{justifyContent:'center',alignItems:'center',display:'flex',fontSize:'36px'}}>
                <img src={Logo} width={'200px'} height={'200px'}/>
                <br>
                </br>
                <p>Welcome To HydraTrade</p>
              </CardHeader>
              <CardBody>
                <Row style={{justifyContent:'center',alignItems:'center'}}>
                  <Col lg="4" style={{marginTop:'100px'}}>
                    <Label style={{fontSize:'24px'}}>
                      EMail
                    </Label>
                    <Input type='text' placeholder='Please insert your email here' bsSize='sm' style={{fontSize:'24px'}} value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                  </Col>
                </Row>
                <Row style={{justifyContent:'center',alignItems:'center'}}>
                  <Col md="4" style={{marginTop:'50px'}}>
                    <Label style={{fontSize:'24px'}}>
                      Password
                    </Label>
                    <Input type='password' placeholder='Please insert your password here' bsSize='sm' style={{fontSize:'24px'}} value={password} onChange={(e)=>{setPassword(e.target.value)}} onKeyDown={(e)=>(e.key == "Enter" ? login() : null)}/>
                  </Col>
                </Row>
                <Row style={{justifyContent:'center',alignItems:'center'}}>
                    <Button className='btn-fill btn-primary' style={{marginTop:'30px',backgroundImage:'linear-gradient(to bottom left, #CBA557, #CBA557, #CBA557)'}} onClick={login}>Log In</Button>                  
                </Row>
                
                
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Login;
