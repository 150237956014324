import { createContext, useContext, useMemo, useState } from "react";

const OHLCContext = createContext(null);

export const OHLCProvider = ({ children }) => {
  const [ohlc, setOhlc] = useState();

  const value = useMemo(
    () => ({
      ohlc,
      setOhlc,
    }),
    [ohlc, setOhlc]
  );
  return <OHLCContext.Provider value={value}>{children}</OHLCContext.Provider>;
};

const useOHLCContext = () => {
  const context = useContext(OHLCContext);

  if (context === undefined) {
    throw new Error("useEvent should be used inside EventProvider");
  }

  return context;
};

export default useOHLCContext;
