import { Col, CardTitle } from "reactstrap";

export default function OrderHeader(props) {
  return (
    <>
      <Col className="text-left" sm="3">
        <h5 className="card-category">Total Trades</h5>
        <CardTitle tag="h2">{props.data?.total_trades}</CardTitle>
      </Col>
      <Col className="text-left" sm="3">
        <h5 className="card-category">Closed Trades</h5>
        <CardTitle tag="h2">{props.data?.closed_trades}</CardTitle>
      </Col>
      <Col className="text-left" sm="3">
        <h5 className="card-category">Net Profit</h5>
        <CardTitle tag="h2">${props.data?.net_profit}</CardTitle>
      </Col>
      <Col className="text-left" sm="3">
        <h5 className="card-category">Profit Percentage</h5>
        <CardTitle tag="h2">{Math.round((props.data?.win_trades / props.data?.closed_trades) * 100, 2)}%</CardTitle>
      </Col>
    </>
  );
}
