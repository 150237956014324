import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bigChartData: {
        labels: [],
        datasets: [
            {
                label: '',
                fill: true,
                // backgroundColor: gradientStroke,
                borderColor: "#1f8ef1",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: "#1f8ef1",
                pointBorderColor: "rgba(255,255,255,0)",
                pointHoverBackgroundColor: "#1f8ef1",
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: []
            }
        ]
    }
};

export const bigChartDataSlice = createSlice({
    name: "bigChartData",
    initialState,
    reducers: {
        setData: (state, { payload }) => {

            state.bigChartData = payload;
        },
        setName: (state, { payload }) => {
            state.bigChartData.datasets[0].label = payload;
        },
        setY: (state, { payload }) => {
            state.bigChartData.labels = payload;
        },
        setX: (state, { payload }) => {
            state.bigChartData.datasets[0].data = payload;
        }
    },
});

export const { setData, setName, setY, setX } = bigChartDataSlice.actions;
export default bigChartDataSlice.reducer;
