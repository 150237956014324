import { createContext, useContext, useMemo, useState } from "react";

const SymbolTFContext = createContext(null);

export const SymbolTfProvider = ({ children }) => {
  const [symbolTf, setSymbolTF] = useState();

  const value = useMemo(
    () => ({
      symbolTf,
      setSymbolTF,
    }),
    [symbolTf, setSymbolTF]
  );
  return <SymbolTFContext.Provider value={value}>{children}</SymbolTFContext.Provider>;
};

const useSymbolTfContext = () => {
  const context = useContext(SymbolTFContext);

  if (context === undefined) {
    throw new Error("useEvent should be used inside EventProvider");
  }

  return context;
};

export default useSymbolTfContext;
