/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import Logo1 from "assets/img/logo/logo1.png";
import Logo2 from "assets/img/logo/logo2.png";
import Logo from "assets/img/logo.png";
import { useHistory } from "react-router-dom";
// reactstrap components
import {
  Row,
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
} from "reactstrap";

import { Link } from "@mui/material";
import HotPairLine from "./HotPairLine";

function AdminNavbar(props) {
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [modalSearch, setmodalSearch] = React.useState(false);
  const [color, setcolor] = React.useState("navbar-transparent");
  const [loggedIn, setLoggedIn] = React.useState(false);
  const history = useHistory();
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    let logged = window.localStorage.getItem("logged");
    if (logged === "loggedIn") {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };

  const logOut = () => {
    window.localStorage.setItem("logged", "loggedOut");
    setLoggedIn(false);
  };
  return (
    <>
      <Navbar className={classNames("navbar-absolute", color)} expand="lg">
        <Container fluid>
          <div className="content" style={{ width: "100%" }}>
            <Row>
              <div className="navbar-wrapper">
                <div
                  className={classNames("navbar-toggle d-inline", {
                    toggled: props.sidebarOpened,
                  })}
                >
                  <div
                    className="navbar-toggle d-inline text-white"
                    onClick={() => history.push("/admin/dasbhboard")}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={Logo} width={"50px"} height={"50px"} />
                    {props.brandText.toUpperCase()}
                  </div>
                  <div
                    className="navbar-toggle d-inline text-white"
                    style={{ marginLeft: "20px", cursor: "pointer" }}
                    onClick={() => history.push("/admin/history")}
                  >
                    History
                  </div>
                  <div
                    className="navbar-toggle d-inline text-white"
                    style={{ marginLeft: "20px", cursor: "pointer" }}
                    onClick={() => history.push("/admin/order")}
                  >
                    Orders
                  </div>
                  <div
                    className="navbar-toggle d-inline text-white"
                    style={{ marginLeft: "20px", cursor: "pointer" }}
                    onClick={() => history.push("/admin/market")}
                  >
                    Market
                  </div>
                  <NavbarToggler onClick={props.toggleSidebar}>
                    <span className="navbar-toggler-bar bar1" />
                    <span className="navbar-toggler-bar bar2" />
                    <span className="navbar-toggler-bar bar3" />
                  </NavbarToggler>
                </div>
              </div>

              <NavbarToggler onClick={toggleCollapse}>
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
              </NavbarToggler>
              <Collapse navbar isOpen={collapseOpen}>
                <Nav className="ml-auto" navbar>
                  {/* <InputGroup className="search-bar">
                  <Button color="link" onClick={toggleModalSearch}>
                    <i className="tim-icons icon-zoom-split" />
                    <span className="d-lg-none d-md-block">Search</span>
                  </Button>
                </InputGroup>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                  >
                    <div className="notification d-none d-lg-block d-xl-block" />
                    <i className="tim-icons icon-sound-wave" />
                    <p className="d-lg-none">Notifications</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Mike John responded to your email
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        You have 5 more tasks
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Your friend Michael is in town
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Another notification
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Another one
                      </DropdownItem>
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
                  <UncontrolledDropdown nav>
                    <DropdownToggle caret color="default" nav onClick={(e) => e.preventDefault()}>
                      <div className="photo">
                        {loggedIn ? (
                          <img alt="..." src={require("assets/img/anime3.png")} />
                        ) : (
                          <img alt="..." src={require("assets/img/default-avatar.png")} />
                        )}
                      </div>
                      <b className="caret d-none d-lg-block d-xl-block" />
                      <p className="d-lg-none">Log out</p>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-navbar" right tag="ul">
                      {loggedIn ? null : (
                        <NavLink tag="li">
                          <DropdownItem
                            className="nav-item"
                            onClick={() => {
                              history.push("/admin/login");
                            }}
                          >
                            Log In
                          </DropdownItem>
                        </NavLink>
                      )}

                      <NavLink tag="li">
                        <DropdownItem
                          className="nav-item"
                          onClick={() => {
                            history.push("/admin/register");
                          }}
                        >
                          Sign Up
                        </DropdownItem>
                      </NavLink>
                      {loggedIn ? (
                        <>
                          <NavLink tag="li">
                            <DropdownItem
                              className="nav-item"
                              onClick={() => {
                                history.push("/admin/user-profile");
                              }}
                            >
                              Profile
                            </DropdownItem>
                          </NavLink>
                          <NavLink tag="li">
                            <DropdownItem
                              className="nav-item"
                              onClick={() => {
                                history.push("/admin/settings");
                              }}
                            >
                              Settings
                            </DropdownItem>
                          </NavLink>
                          <DropdownItem divider tag="li" />
                          <NavLink tag="li">
                            <DropdownItem className="nav-item" onClick={() => logOut()}>
                              Log out
                            </DropdownItem>
                          </NavLink>
                        </>
                      ) : null}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <li className="separator d-lg-none" />
                </Nav>
              </Collapse>
            </Row>

            <HotPairLine />
          </div>
        </Container>
      </Navbar>
      <Modal modalClassName="modal-search" isOpen={modalSearch} toggle={toggleModalSearch}>
        <ModalHeader>
          <Input placeholder="SEARCH" type="text" />
          <button aria-label="Close" className="close" onClick={toggleModalSearch}>
            <i className="tim-icons icon-simple-remove" />
          </button>
        </ModalHeader>
      </Modal>
    </>
  );
}

export default AdminNavbar;
