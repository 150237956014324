import { createContext, useContext, useMemo, useState } from "react";

const RecentTradesContext = createContext(null);

export const RecentTradesProvider = ({ children }) => {
  const [recentTrades, setRecentTrades] = useState();

  const value = useMemo(
    () => ({
      recentTrades,
      setRecentTrades,
    }),
    [recentTrades, setRecentTrades]
  );
  return <RecentTradesContext.Provider value={value}>{children}</RecentTradesContext.Provider>;
};

const useRecentTradesContext = () => {
  const context = useContext(RecentTradesContext);

  if (context === undefined) {
    throw new Error("useEvent should be used inside EventProvider");
  }

  return context;
};

export default useRecentTradesContext;
