import React from "react";
import { Form } from "reactstrap";
import TradeSettings from "components/Settings/TradeSettings";
import SafetySettings from "components/Settings/SafetySettings";
import AskBidSettings from "components/Settings/AskBidSettings";
import PositionSettings from "components/Settings/PositionSettings";
function Settings() {
  return (
    <div className="content" style={{ padding: "160px 100px 30px 100px" }}>
      <Form>
        <TradeSettings />
        <SafetySettings />
        <AskBidSettings />
        <PositionSettings />
      </Form>
    </div>
  );
}

export default Settings;
