import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    klineData: [

    ]
};

export const klineDataSlice = createSlice({
    name: "klineData",
    initialState,
    reducers: {
        setkLineData: (state, { payload }) => {

            state.klineData = payload;
        }
    },
});

export const { setkLineData } = klineDataSlice.actions;
export default klineDataSlice.reducer;
