import AskTrendsPanel from "./AskTrendsPanel";
import BidTrendsPanel from "./BidTrendsPanel";
import VolumeTrendsPanel from "./VolumeTrendsPanel";
import PercentagePanel from "./PercentagePanel";
import { Row, Col } from "reactstrap";
export default function TrendsPanel(props) {
  return (
    <Row>
      <Col lg="3">
        <AskTrendsPanel />
      </Col>
      <Col lg="3">
        <BidTrendsPanel />
      </Col>
      <Col lg="3">
        <PercentagePanel />
      </Col>
      <Col lg="3">
        <VolumeTrendsPanel />
      </Col>
    </Row>
  );
}
